.container {
  margin-bottom: 10px
}
.container:last-child {
  margin-bottom: 0;
}
.fullWidth {
  width: 100%;
}

.button {
  width: 100%;
  height: var(--dimension-input-height);
  padding: 0 15px;
  font-size: var(--font-size-default);
  font-weight: 700;
  line-height: var(--dimension-input-height);
  border-radius: var(--dimension-border-radius);
  border: 0;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  cursor: pointer;
  
}
a.button {
  text-align: center;
  text-decoration: none !important;
  line-height: calc(var(--dimension-input-height) - 1px);
  display: block;
}

/* 
  Colors
*/
.yellow .button {
  color: var(--color-blue-darkest);
  background: var(--color-yellow-bright);
  border-bottom-color: var(--color-yellow-dark)
}
.dark .button {
  color: #ffffff;
  background: var(--color-blue-darkest);
  border-bottom-color: var(--color-blue-darkest);
  font-weight: 400;
}
.white .button {
  color: var(--color-blue-darkest);
  background: #fff;
  border: solid 1px var(--color-grey-lighter);
  border-bottom-width: 2px;
  font-weight: 700;
}
.transparent .button {
  color: var(--color-grey-lighter);
  background: transparent;
  border: solid 1px var(--color-grey-dark);
  border-bottom-width: 2px;
  font-weight: 400;
}

/* 
  Sizes
*/
.small .button {
  height: 30px;
  line-height: 30px;
  font-size: 14px
}

/* 
  Disabled
*/
.button[disabled] {
  background: var(--color-disabled-background);
  color: var(--color-disabled-foreground);
  border-bottom-color: var(--color-form-border);
  cursor: not-allowed
}