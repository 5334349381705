.container {
  width: 100%;
  height: var(--dimension-input-height);
  margin-bottom: 10px;
  border: 0;
  border-radius: var(--dimension-border-radius);
  box-shadow: 0 1px 2px rgb(0 0 0 / 30%);
  font-family: 'Roboto', sans-serif;
  color: #fff;
  line-height: var(--dimension-input-height);
  position: relative;
  cursor: pointer;
}
.container:last-child {
  margin-bottom: 0;
}
.container::after {
  content: '';
  width: 43px;
  height: 43px;
  background-color: #fff;
  background-repeat: no-repeat;
  border-radius: var(--dimension-border-radius);
  left: 1px;
  top: 1px;
  position: absolute;
}

.google {
  background: var(--color-google-blue);
}
.google::after {
  background-image: url(../../../img/google.svg);
  background-size: 26px auto;
  background-position: 50% 50%;
}
.linkedin {
  background: var(--color-linkedin-blue)
}
.linkedin::after {
  background-image: url(../../../img/linkedin.svg);
  background-size: 28px auto;
  background-position: 60% 50%;
}