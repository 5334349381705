.container {
  width: 100%;
  margin-bottom: 10px;
  align-items: flex-start;
  display: flex;
}
.container:last-child { margin: 0 }
.left {
  margin: 0 6px 0 0;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 0;
}
.right {
  color: var(--color-grey-medium);
  font-size: var(--font-size-small);
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
}