/*
  Variables
*/
:root {
  /* colours */
  --color-blue-darkest: #1F2027;
  --color-grey-lightest: #F8F8F8;
  --color-grey-lighter: #DDD;
  --color-grey-light: #ADADAD;
  --color-grey-medium: #777;
  --color-yellow-bright: #FECF00;
  --color-yellow-light: #FEF9E7;
  --color-yellow-dark: #E4B900;
  --color-green-bright: #2FB457;
  --color-blue-bright: #5787C6;
  --color-orange-bright: #FC8527;
  --color-red-bright: #D0021B;

  --color-google-blue: #478BEF;
  --color-linkedin-blue: #0073B1;

  /* Dimensions */
  --dimension-padding-structure: 60px;
  --dimension-border-radius: 4px;
  --dimension-input-height: 45px;

  /* Fonts */
  --font-family-default: 'Lato', sans-serif;
  --font-size-default: 16px;
  --font-size-small: 14px;
  --font-size-medium: 20px;
  --font-size-large: 24px;

  /* structure */
  --color-body-background: var(--color-grey-lightest);
  --color-body-foreground: var(--color-blue-darkest);
  --color-form-placeholder: var(--color-grey-light);
  --color-form-border: var(--color-grey-lighter);
  --color-disabled-foreground: var(--color-grey-light);
  --color-disabled-background: var(--color-grey-lighter);
  --color-error: var(--color-red-bright);
  --color-warning: var(--color-orange-bright);
  --color-success: var(--color-green-bright);
}

/*
  Reset
*/
*, ::before, ::after { box-sizing: border-box }
ul[class], ol[class], fieldset { padding: 0 }
body, h1, h2, h3, h4, p, ul[class], ol[class], li, i, figure, figcaption, blockquote, dl, dd, fieldset { margin: 0; font-weight: 400; font-style: normal; font-family: var(--font-family-default); }
body { min-height: 100vh; scroll-behavior: smooth; text-rendering: optimizeSpeed; line-height: 1.5 }
ul[class], ol[class] { list-style: none }
a:not([class]) { text-decoration-skip-ink: auto }
img { max-width:100%; border: 0; display: block }
input, button, textarea,select { font: inherit }
:focus, fieldset { outline:none }
fieldset { min-width: 0; border:0 }
body:not(:-moz-handler-blocked) fieldset { display: table-cell }
a, a:visited, a:visited:hover, a:active { text-decoration: none }
@media (prefers-reduced-motion: reduce) {*{ animation-duration: .01ms!important; animation-iteration-count: 1!important; transition-duration: .01ms!important; scroll-behavior: auto!important }}
input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus textarea:-webkit-autofill, textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus, select:-webkit-autofill, select:-webkit-autofill:hover, select:-webkit-autofill:focus { box-shadow: none; -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important; }

/*
  Structure
*/
#root {
  height: 100vh
}
body {
  background: var(--color-body-background);
  font-size: var(--font-size-default);
  color: var(--color-body-foreground);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
::placeholder {
  color: var(--color-form-placeholder);
}

/*
  Hyperlinks
*/
a:link,
a:visited,
a:hover,
a:active { 
  color: var(--color-blue-bright);
  text-decoration: none;
}
a:hover, a:visited:hover {
  text-decoration: underline;
}
a.black { color: var(--color-blue-darkest) !important }

/* 
  Typography
*/
h1 {
  margin: 0 0 8px;
  font-size: var(--font-size-large);
  color: var(--color-blue-darkest);
  font-weight: 700;
}
p { margin-bottom: 12px }
p:last-child { margin-bottom: 0 }

/* 
  Utils
*/
.center { text-align: center !important }
.muted { color: var(--color-grey-medium) !important }

/* 
  Misc
*/
.signage-iframe {
  width: 0;
  height: 0;
  display: none;
}