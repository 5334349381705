.container {
  width: 100%;
}

.immitate, .input {
  width: 100%;
  height: var(--dimension-input-height);
  padding: 8px 15px;
  font-size: var(--font-size-default);
  color: var(--color-body-foreground);
  border: solid 1px var(--color-form-border);
  border-radius: var(--dimension-border-radius);
}
.input {
  transition: border-color .1s linear;
  background: #fff;
}
.input:hover, .input:focus, .input:active {
  border-color: var(--color-blue-darkest)
}
.input[disabled], .immitate {
  background: #eee;
  color: var(--color-disabled-foreground);
  border-color: var(--color-disabled-background) !important;
  text-shadow: 1px 1px 1px #fff;
  cursor: not-allowed;
}
.error {
  border-color: var(--color-error) !important;
  box-shadow: 0 0 6px rgba(208, 2, 27, .4)
}