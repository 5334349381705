.container {
  width: 600px;
  padding: var(--dimension-padding-structure);
  flex-basis: 600px;
  flex-grow: 0;
  flex-shrink: 0;
  background-color: var(--color-blue-darkest);
  color: #fff;
  position: relative;
}

.logo {
  margin-bottom: 40px;
}

.intro {
  margin: 0 0 40px;
}
.intro .label {
  margin: 0 0 10px;
  font-size: var(--font-size-medium);
  font-weight: 700;
}
.intro .summary {
  color: var(--color-grey-light)
}

.signage {
  font-size: var(--font-size-default);
  color: #fff !important;
  left: var(--dimension-padding-structure);
  bottom: var(--dimension-padding-structure);;
  position: absolute;
}