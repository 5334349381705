.container {
  position: fixed;
  top: 0;
  left: 0;
  height: 4px;
  display: block;
  width: 100%;
  background-color: var(--color-yellow-bright);
  background-clip: padding-box;
  overflow: hidden;
  z-index: 9999;
}

.indeterminate {
  background-color: var(--color-yellow-light);
}
.indeterminate:before {
  content: '';
  position: absolute;
  background-color: inherit;
  top: 0;
  left: 0;
  bottom: 0;
  will-change: left, right;
  -webkit-animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
  animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
}
.indeterminate:after {
  content: '';
  position: absolute;
  background-color: inherit;
  top: 0;
  left: 0;
  bottom: 0;
  will-change: left, right;
  -webkit-animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
  animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
  -webkit-animation-delay: 1.15s;
  animation-delay: 1.15s;
}
@-webkit-keyframes indeterminate {
  0% {
    left: -35%;
    right: 100%;
  }
  60% {
    left: 100%;
    right: -90%;
  }
  100% {
    left: 100%;
    right: -90%;
  }
}
@keyframes indeterminate {
  0% {
    left: -35%;
    right: 100%;
  }
  60% {
    left: 100%;
    right: -90%;
  }
  100% {
    left: 100%;
    right: -90%;
  }
}
@-webkit-keyframes indeterminate-short {
  0% {
    left: -200%;
    right: 100%;
  }
  60% {
    left: 107%;
    right: -8%;
  }
  100% {
    left: 107%;
    right: -8%;
  }
}
@keyframes indeterminate-short {
  0% {
    left: -200%;
    right: 100%;
  }
  60% {
    left: 107%;
    right: -8%;
  }
  100% {
    left: 107%;
    right: -8%;
  }
}

.blocking {
  width: 100vw;
  height: 100vh;
  background: var(--color-body-background);
  align-items: center;
  justify-content: center;
  display: flex;
  left: 0;
  top: 0;
  position: fixed;
}

.blocking .spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.blocking .spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}
.blocking .spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: var(--color-blue-darkest);
}
.blocking .spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.blocking .spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.blocking .spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.blocking .spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.blocking .spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.blocking .spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.blocking .spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.blocking .spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.blocking .spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.blocking .spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.blocking .spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.blocking .spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}