.container {
  margin: 0 0 40px;
  padding: 0 0 20px;
  border-bottom: solid 1px var(--color-grey-medium)
}
.label {
  margin: 0 0 8px;
  color: var(--color-grey-light);
  font-size: var(--font-size-small);
}
.name {
  color: #fff;
  font-size: var(--font-size-large);
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}