.container {
  padding: var(--dimension-padding-structure);
  background: var(--color-body-background);
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
}