.container {
  margin: 0 0 12px;
  font-size: var(--font-size-small);
  align-items: center;
  display: flex
}
.container:last-child {
  margin: 0;
}

.left {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
}
.right {
  margin-left: 20px;
  white-space: nowrap;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 0;
}

.title {
  margin: 0;
  color: var(--color-blue-darkest)
}
.note, .description {
  margin: 0;
  color: var(--color-grey-medium)
}
.description {
  font-style: italic;
  opacity: .6;
}