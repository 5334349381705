.item {
  height: 40px;
  margin: 0 0 12px;
  padding: 0 0 0 50px;
  background-repeat: no-repeat;
  background-position: 0 50%;
  background-size: auto 35px;
  flex-direction: column;
  justify-content: center;
  display: flex;
}
.item:last-child {
  margin: 0 
}
.item p {
  margin: 0 
}
.item span {
  font-size: var(--font-size-small);
  color: var(--color-grey-light)

}
.item.incomplete {
  background-image: url(../../../img/check-grey.svg);
  color: var(--color-grey-light)
}
.item.complete {
  background-image: url(../../../img/check-yellow.svg);
  color: #fff;
  cursor: pointer;
}