div.container {
  width: 100%;
  margin-bottom: 10px;
  justify-content: space-between;
  align-items: center;
  display: flex;
}
.container:last-child {
  margin-bottom: 0;
}

.columns-2 > * {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(50% - calc(10px / 2));
}