.container {
  width: 100%;
  max-width: 440px;
  margin-bottom: 40px;
}
.container:last-child {
  margin-bottom: 0
}
.wider {
  max-width: 660px;
}
.less {
  margin-bottom: 24px
}