.container {
  width: 100%;
  margin: 0 0 10px;
  background: #fff;
  border: solid 1px var(--color-grey-lighter);
  border-radius: var(--dimension-border-radius)
}
.container:last-child {
  margin: 0
}
.container.passed {
  background-image: url(../../../img/check-green.svg);
  background-repeat: no-repeat;
  background-position: calc(100% - 16px) 29px;
  background-size: 17px auto;
}

.top {
  padding: 16px 46px 16px 45px;
  background-repeat: no-repeat;
  background-position: 16px 18px;
  background-size: 20px auto;
}
.top.account {
  background-image: url(../../../img/review-account.svg);
  background-position: 16px 19px;
  background-size: 18px auto;
}
.top.payment {
  background-image: url(../../../img/review-payments.svg);
}
.top.hardware {
  background-image: url(../../../img/review-hardware.svg);
}
.top.content {
  background-image: url(../../../img/review-content.svg);
}
.title {
  margin: 0;
  font-size: var(--font-size-default);
  color: var(--color-blue-darkest)
}
.info {
  margin: 0;
  font-size: var(--font-size-small);
  color: var(--color-grey-medium);
}

.bottom {
  padding: 16px 16px 16px 45px;
  border-top: solid 1px var(--color-grey-lighter);
  font-size: var(--font-size-small)
}